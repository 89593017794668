import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from "../components/layout"

const MdxPage = ({
  data: {
    mdx: {
      frontmatter: { title, headerImage, contentImage },
      body,
    },
  },
}) => {
  return (
    <Layout title={title}>
      <MDXProvider>
        <MDXRenderer
          headerImage={headerImage}
          contentImage={contentImage}
        >
          {body}
        </MDXRenderer>
      </MDXProvider>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        headerImage {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              width: 960,
              height: 280,
              transformOptions: {
                cropFocus: ENTROPY
              }
            )
          }
        }
        contentImage {
          childImageSharp {
            gatsbyImageData(
              layout: FIXED,
              width: 200,
            )
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;
